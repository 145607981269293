<template>
	<div id="app">
		<!-- <remote-script src="http://www.tantongheyi.com/static/js2/jquery-1.12.4.min.js"></remote-script> -->
		<transition name="fade-transform">
			<router-view />
		</transition>


	</div>
</template>

<script>
	export default {
		name: 'App',
		data() {
			return {
			
			}
		},
		components: {

		}
	}
</script>
<!-- <template>
  <div id="app">
    <img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome2 to Your Vue.js App"/>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'app',
  components: {
    HelloWorld
  }
}
</script> -->

<style>
#app {
	font-family: 'Avenir', Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
}
/* fade-transform */

.fade-transform-enter {
 opacity:0;
}
.fade-transform-leave{
 opacity:1;
}
.fade-transform-enter-active{
 transition:opacity .5s;
}
.fade-transform-leave-active{
 opacity:0;
 transition:opacity .5s;
}

</style>
