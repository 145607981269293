import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
// import Layout from '@/layout'

export const constantRoutes = [
	{
		path: '/',
		component: () => import('@/views/index/index'),
		meta: {title: '首页'}
	},{
		path: '/member',
		component: () => import('@/views/member/index'),
		meta: {title: '会员说明'}
	},{
		path: '/about',
		component: () => import('@/views/about/index'),
		meta: {title: '平台简介'}
	},{
		path: '/goods/tian',
		component: () => import('@/views/goods/tian'),
		meta: {title: '天款酱香酒'}
	},{
		path: '/goods/ren',
		component: () => import('@/views/goods/ren'),
		meta: {title: '人款酱香酒'}
	},{
		path: '/goods/tianyue',
		component: () => import('@/views/goods/tianyue'),
		meta: {title: '天约酒'}
	},{
		path: '/agreement/user',
		component: () => import('@/views/agreement/user'),
		meta: {title: '叁零荟用户注册和服务协议'}
	},{
		path: '/agreement/privacy',
		component: () => import('@/views/agreement/privacy'),
		meta: {title: '叁零荟隐私保护条款'}
	},{
		path: '/agreement/member',
		component: () => import('@/views/agreement/member'),
		meta: {title: '叁零荟酒友会会员规则'}
	},{
		path: '/agreement/partner',
		component: () => import('@/views/agreement/partner'),
		meta: {title: '叁零荟酒友会合伙人规则'}
	}
]

const createRouter = () => new Router({
	mode: 'history', // require service support
	scrollBehavior: () => ({ y: 0 }),
	routes: constantRoutes,
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
	const newRouter = createRouter()
	router.matcher = newRouter.matcher // reset router
}
// router.afterEach((to, from) => {
//  let path = to.fullPath.slice(1) // 去除'/'
//     let url
//     const jsApiList = [
//       'onMenuShareAppMessage',
//       'onMenuShareTimeline',
//       'chooseWXPay',
//       'showOptionMenu',
//       "updateAppMessageShareData",
//       "hideMenuItems",
//       "showMenuItems"
//     ]

//     if (!sessionStorage.getItem('initLink')) {
//       // 解决ios微信下，分享签名不成功的问题,将第一次的进入的url缓存起来。
//       sessionStorage.setItem('initLink', document.URL)
//     }
//     if (!!window.__wxjs_is_wkwebview) {
//       // ios
//       url = sessionStorage.getItem('initLink')
//       wechatUtil.setWeChatConfig(url, jsApiList)
//     } else {
//       // 安卓
//       url = location.origin + process.env.BASE_URL + path
//       // setTimeout(() => {
//       wechatUtil.setWeChatConfig(url, jsApiList)
//       // }, 0)
//     }
// })

export default router
